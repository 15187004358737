.search_form__counter_item{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 1rem 0;
}
.search_form__counter_item_label{
    flex-basis: 60%;
    max-width: 60%;
}
.search_form__counter_item_label > *{
    display: block;
}
.search_form__counter_item_label small{
    color: #707070;
    font-size: 80%;
    line-height: 1.1;
    margin-top: 3px;
}
.search_form__counter_item_control{
    display: flex;
    justify-content: space-between;

    flex-basis: 40%;
    max-width: 40%;
}
.search_form__counter_item_control .count{
    min-width: auto;
    height: 30px;
}
.search_form__counter_item_control >  .increment, .count, .dicrement {
    text-align: center;
    max-width: 33%;
    font-size: 16px;
}


.search_form__counter_item_control >  .increment, .dicrement {
    border-radius: 100%;
    height: 30px;
    width: 30px;
    color: white;
    background: #ff9828;

    font-size: 24px;
    cursor: pointer;
    transition: transform 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_form__counter_item_control > .dicrement{
    background: #104a9c;
}
.search_form__counter_item_control >  .increment:active, .dicrement:active{
    transform: scale(0.9);
    transition: font-size 0.2s;
}